import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FaBars, FaTimes } from "react-icons/fa"
import logo from "../images/logo.png"
import white_logo from "../images/white_logo.png"

const DesktopNavbar = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row;
`

const MobileNavbar = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row;
  @media (min-width: 768px) {
    display: none;
  }
`
const HamburgerMenu = styled.div`
  display: block;

  z-index: 2;
`
const Nav = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: column;
`
const Logo = styled.div`
  display: flex;
  flex-grow: 0.3;
`

const Menu = styled.div`
  display: flex;
  flex-grow: 0.7;
  justify-content: flex-end;
`
const MenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  right: 0;
`
const MenuItem = styled.li`
  float: left;
  text-decoration: none;
  padding: 10px;
  left: 0;
`
const MenuItemLink = styled(Link)`
  display: block;
  color: black;
  font-size: 1.3rem;
  padding: 0rem;
  font-family: montserrat;
  text-decoration: none;
`
const MenuItemLinkExternal = styled.a`
  display: block;
  color: black;
  font-size: 1.3rem;
  padding: 0rem;
  font-family: montserrat;
  text-decoration: none;
`
const MobileMenuItems = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overflow-y: hidden;
  background-color: black;
  display: ${props => (props.t ? "none" : "block")};
  @media (min-width: 768px) {
    display: none;
  }
`
const MobileMenuItem = styled.div`
  margin: 10% auto;
  padding: 50px;
  text-align: center;
  text-decoration: none;
  width: 80%;
`

class Navbar extends React.Component {
  constructor() {
    super()
    this.state = {
      toggle: true,
    }
  }
  setToggle = () => {
    this.setState({
      toggle: !this.state.toggle,
    })
  }
  render() {
    let bars = (
      <FaBars
        color={"black"}
        size={"2.0em"}
        onClick={() => {
          this.setToggle()
        }}
      />
    )

    let cross = (
      <FaTimes
        color={"white"}
        size={"2.0em"}
        onClick={() => {
          this.setToggle()
        }}
      />
    )

    return (
      <>
        <MobileMenuItems t={this.state.toggle}>
          <MobileMenuItem>
            <Link
              to="/"
              onClick={() => {
                this.setToggle(false)
              }}
            >
              <img src={`${logo}`} width="150px" />
            </Link>
            <h3>
              <Link
                to="/"
                style={{
                  display: "block",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "0rem",
                  fontFamily: "montserrat",
                  textDecoration: "none",
                }}
              >
                Home
              </Link>
            </h3>
            <h3>
              <Link
                to="/demo"
                style={{
                  display: "block",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "0rem",
                  fontFamily: "montserrat",
                  textDecoration: "none",
                }}
              >
                Demo
              </Link>
            </h3>
            <h3>
              <Link
                to="/pricing"
                style={{
                  display: "block",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "0rem",
                  fontFamily: "montserrat",
                  textDecoration: "none",
                }}
              >
                Pricing
              </Link>
            </h3>
            <h3>
              <Link
                to="/terms"
                style={{
                  display: "block",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "0rem",
                  fontFamily: "montserrat",
                  textDecoration: "none",
                }}
              >
                Privacy
              </Link>
            </h3>
            <h3>
              <Link
                to="/terms"
                style={{
                  display: "block",
                  color: "white",
                  fontSize: "1.3rem",
                  padding: "0rem",
                  fontFamily: "montserrat",
                  textDecoration: "none",
                }}
              >
                Terms
              </Link>
            </h3>
          </MobileMenuItem>
        </MobileMenuItems>

        <Nav>
          <DesktopNavbar>
            <Logo>
              <Link to="/">
                <img src={`${logo}`} width="150px" />
              </Link>
            </Logo>
            <Menu>
              <MenuList>
                <MenuItem>
                  <MenuItemLink to="/">Home</MenuItemLink>
                </MenuItem>
                <MenuItem>
                  <MenuItemLink to="/demo">Demo</MenuItemLink>
                </MenuItem>
                <MenuItem>
                  <MenuItemLink to="/pricing">Pricing</MenuItemLink>
                </MenuItem>
                <MenuItem>
                  <MenuItemLink to="/privacy">Privacy</MenuItemLink>
                </MenuItem>
                <MenuItem>
                  <MenuItemLink to="/terms">Terms</MenuItemLink>
                </MenuItem>
              </MenuList>
            </Menu>
          </DesktopNavbar>
          <MobileNavbar>
            <Logo>
              <Link to="/">
                <img src={`${logo}`} width="150px" />
              </Link>
            </Logo>
            <Menu>
              <HamburgerMenu>{this.state.toggle ? bars : cross}</HamburgerMenu>{" "}
            </Menu>
          </MobileNavbar>
        </Nav>
      </>
    )
  }
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
