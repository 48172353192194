import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {
  FaWhatsapp,
  FaPhone,
  FaTwitter,
  FaFacebook,
  FaEnvelope,
  FaMapMarker,
  FaYoutube,
  FaYoutubeSquare,
  FaPinterest,
  FaInstagram
} from "react-icons/fa"

const FooterBar = styled.div`
  flex-flow: column;
  bottom: 0;
`
const FooterBarCopyright = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-flow: row;
  bottom: 0;
  color: white;
  background-color: #353535;
`

const Copyright = styled.div`
  color: white;
  font-family: montserrat;
  padding: 2rem;
`

const SocialMedia = styled.div`
  color: white;
  font-family: montserrat;
  margin-left: auto;
  display: flex;
  flex-flow: row;
  font-size: 2rem;
  padding: 0 4rem 0rem 4rem;
`

const DesktopFooter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  bottom: 0;
  @media (max-width: 768px) {
    flex-flow: column;
  }
  @media (min-width: 768px) {
    flex-flow: row;
  }
`
const FooterBlock = styled.div`
  flex: 1;
  font-family: montserrat;
  line-height: 2rem;
  padding: 2rem;
  height: "100%";
`

const Footer = ({ siteTitle }) => (
  <div>
    <footer
      style={{
        bottom: 0,
        relative: "fixed",
        background: "#404040",
      }}
    ></footer>
    <FooterBarCopyright>
      <Copyright>Copyright © 2020 All rights reserved</Copyright>
      <SocialMedia>
      <a
          href="https://www.instagram.com/inflowkit/"
          style={{ padding: "1rem 0.5rem 0 0.5rem" }}
        >
          <FaInstagram width="30px" color="white" />
        </a>
      <a
          href="https://www.pinterest.com/inflowkit/"
          style={{ padding: "1rem 0.5rem 0 0.5rem" }}
        >
          <FaPinterest width="30px" color="white" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCjDvKkx7O_HLG_EBqsJD8Cw/"
          style={{ padding: "1rem 0.5rem 0 0.5rem" }}
        >
          <FaYoutubeSquare width="30px" color="white" />
        </a>
        <a
          href="https://www.twitter.com/inflowkit"
          style={{ padding: "1rem 0.5rem 0 0.5rem" }}
        >
          <FaTwitter width="30px" color="white" />
        </a>
        <a
          href="https://www.facebook.com/inflowkitofficial"
          style={{ padding: "1rem 0.5rem 0 0.5rem" }}
        >
          <FaFacebook width="0.5rem" color="white" />
        </a>
      </SocialMedia>
    </FooterBarCopyright>
  </div>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
